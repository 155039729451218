import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/Layout/Layout'
import { SeoType } from '@/types.d'
import { Gutenberg } from '@/components/Gutenberg'
import ListElement from '@/components/ListElement'

interface sectionsData {
  uri: string
  title: string
  id: string
}

interface DefaultData {
  data: {
    page: {
      title: string
      content: string
      uri: string
      seo: SeoType
      image: any
      blocks: any
      blocksJSON: any
      heroFilter: boolean
    }
    menu?: any
    quickmenu?: any
    sections: {
      nodes: Array<sectionsData>
    }
  }
  pageContext: {
    postlang: string
  }
}

const ArchiveSections = ({ data }: DefaultData) => {
  // Page data
  const { page, sections } = data
  // Menus
  const { menu, quickmenu } = data

  const heroContent = {
    image: page?.image,
    disableBackButton: true,
    filter: page?.heroFilter,
  }

  // Split Arr to half
  const split = Math.ceil(sections?.nodes?.length / 2)
  // Left col
  const first = sections?.nodes?.slice(0, split)
  // Right col
  const last = sections?.nodes?.slice(
    -split + Number(sections?.nodes?.length % 2 == 0 && sections?.nodes?.length != 1 ? 0 : 1)
  )

  return (
    <Layout
      title={page?.title}
      hero={heroContent}
      nav={menu}
      quickNav={quickmenu}
      uri={page?.uri}
      seo={page?.seo}
      useBreadCrump={true}
    >
      <div className="mx-auto max-w-[1100px]">
        <Gutenberg blocks={page?.blocks} />
      </div>
      <div className="mx-auto max-w-[1100px]">
        <div className="grid sm:grid-cols-2 sm:gap-10">
          <div>
            {first?.map(section => {
              return (
                <ListElement internal={true} key={section?.id} to={section?.uri}>
                  {section?.title}
                </ListElement>
              )
            })}
          </div>
          <div>
            {sections?.nodes?.length > 1 &&
              last?.map(section => {
                return (
                  <ListElement internal={true} key={section?.id} to={section?.uri}>
                    {section?.title}
                  </ListElement>
                )
              })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const PageQuery = graphql`
  query SectionsPageById($id: String, $sectionLang: Boolean) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      uri
      heroFilter: gwShowPostHeaderFilter
      ...pageBlocks
      ...seo
      ...featuredHeroImage
    }
    menu: wpMenu(locations: { eq: MAIN_MENU }) {
      ...navigation
    }
    quickmenu: wpMenu(locations: { eq: QUICK_NAV }) {
      ...navigation
    }
    sections: allWpGwSection(sort: { fields: title, order: ASC }, filter: { gwShowPostOnEng: { eq: $sectionLang } }) {
      nodes {
        uri
        title
        id
      }
    }
  }
`

export default ArchiveSections
