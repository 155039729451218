import React from 'react'
import Arrow from '@/assets/Arrow'
import { Link } from 'gatsby'

export interface ListElementProps {
  to: string
  children: string
  internal?: boolean
}

const ListElement = ({ to, children, internal = false }: ListElementProps) => {
  if (internal) {
    return (
      <Link
        className={`flex items-center border-b border-primary py-2 readmore-link`}
        to={to}
        target="_blank"
        rel="noreferrer"
      >
        <div className="w-[25px] h-[25px] mr-3 sm:mr-5 shrink-0 grow-0">
          <Arrow />
        </div>
        {children}
      </Link>
    )
  }
  return (
    <a
      className={`flex items-center border-b border-primary py-2 readmore-link`}
      href={to}
      target="_blank"
      rel="noreferrer"
    >
      <div className="w-[25px] h-[25px] mr-3 sm:mr-5 shrink-0 grow-0">
        <Arrow />
      </div>
      {children}
    </a>
  )
}

export default ListElement
