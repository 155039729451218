import React from 'react'

const Arrow = () => {
  return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <polygon className="cls-2" points="2.61 30 .19 27.5 12.17 15.1 0 2.5 2.42 0 17.01 15.1 2.61 30" />
      <polygon className="cls-1" points="15.6 30 13.19 27.5 25.17 15.1 12.99 2.5 15.41 0 30 15.1 15.6 30" />
    </svg>
  )
}

export default Arrow
